var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex-1 flex items-center justify-center h-full" },
    [
      _vm.showModal
        ? _c(
            "div",
            { staticClass: "modal-template", staticStyle: { "z-index": "1" } },
            [
              _c("div", {
                staticClass: "modal-mask",
                on: {
                  click: function ($event) {
                    _vm.showModal = false
                    _vm.hasErrorsEmail = []
                  },
                },
              }),
              _c("div", { staticClass: "modal-wrapper" }, [
                _c("div", { staticClass: "modal-container" }, [
                  _c("div", { staticClass: "modal-header" }, [
                    _c("h2", { staticClass: "title" }, [
                      _vm._v("Recupera Password"),
                    ]),
                    _c(
                      "button",
                      {
                        staticClass: "modal-default-button text-2xl p-2",
                        on: {
                          click: function ($event) {
                            _vm.showModal = false
                            _vm.hasErrorsEmail = []
                          },
                        },
                      },
                      [_vm._v(" × ")]
                    ),
                  ]),
                  _c("div", { staticClass: "modal-body" }, [
                    _c(
                      "div",
                      { staticClass: "lab-inp-container flex-col md:flex-row" },
                      [
                        _c("p", { staticClass: "mb-4" }, [
                          _vm._v(
                            " Ti invieremo un link per reimpostare la tua passworde "
                          ),
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.email,
                              expression: "email",
                            },
                          ],
                          staticClass: "light-input",
                          class: {
                            hasErrors: _vm.hasErrorsEmail.length > 0,
                          },
                          attrs: {
                            placeholder: "Email",
                            id: "email",
                            type: "email",
                          },
                          domProps: { value: _vm.email },
                          on: {
                            focus: function ($event) {
                              _vm.hasErrorsEmail = []
                              _vm.hasErrors = []
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.email = $event.target.value
                            },
                          },
                        }),
                        _c("div", { staticClass: "mx-auto mt-8 w-fit" }, [
                          _c(
                            "button",
                            {
                              staticClass: "reset-password-send-btn",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.resetPassword.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [_vm._v(" Invia ")]
                          ),
                        ]),
                        _vm.hasErrorsEmail.length > 0
                          ? _c(
                              "p",
                              { staticClass: "text-xs font-extralight" },
                              [
                                _vm._v(
                                  " *" +
                                    _vm._s(_vm.hasErrorsEmail.join(" ")) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "modal-footer" }),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _vm.showModalSkin
        ? _c(
            "div",
            {
              staticClass:
                "modal-template fixed w-full h-full flex items-center justify-center",
              staticStyle: { "z-index": "1" },
            },
            [
              _c("div", {
                staticClass: "modal-mask",
                on: {
                  click: function ($event) {
                    _vm.showModalSkin = false
                  },
                },
              }),
              _c("div", { staticClass: "modal-wrapper" }, [
                _c("div", { staticClass: "modal-container" }, [
                  _c("div", { staticClass: "modal-header" }, [
                    _c("h2", { staticClass: "title" }, [
                      _vm._v("Seleziona il tuo casinò attuale"),
                    ]),
                    _c(
                      "button",
                      {
                        staticClass: "modal-default-button text-2xl p-2",
                        on: {
                          click: function ($event) {
                            _vm.showModalSkin = false
                          },
                        },
                      },
                      [_vm._v(" × ")]
                    ),
                  ]),
                  _c("div", { staticClass: "modal-body" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.skinId,
                            expression: "skinId",
                          },
                        ],
                        attrs: { name: "", id: "" },
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.skinId = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                            function ($event) {
                              _vm.loginUser
                              _vm.showModalSkin = false
                            },
                          ],
                        },
                      },
                      [
                        _c(
                          "option",
                          { attrs: { default: "" }, domProps: { value: null } },
                          [_vm._v("Nome del casinò")]
                        ),
                        _vm._l(_vm.skins, function (skin) {
                          return _c(
                            "option",
                            {
                              key: skin.id_skin,
                              domProps: { value: Number(skin.id_skin) },
                            },
                            [_vm._v(" " + _vm._s(skin.name) + " ")]
                          )
                        }),
                      ],
                      2
                    ),
                  ]),
                  _c("div", { staticClass: "modal-footer" }),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _c("div", { attrs: { id: "login-wrapper" } }, [
        _vm.barName
          ? _c(
              "h2",
              {
                staticClass:
                  "w-full text-2xl font-semibold text-secondary text-center uppercase mb-2",
              },
              [_vm._v(" " + _vm._s(_vm.barName) + " ")]
            )
          : _vm._e(),
        _c("div", { staticClass: "relative" }, [
          _vm.skinId && _vm.skinName
            ? _c(
                "p",
                {
                  staticClass:
                    "text-center font-bold tracking-wider mb-3 text-secondary text-lg w-full",
                },
                [_vm._v(" - " + _vm._s(_vm.skinName) + " - ")]
              )
            : _vm._e(),
        ]),
        _vm._m(0),
        _c("div", { staticClass: "w-full mb-1 bg-gray-700 relative" }, [
          _c(
            "form",
            {
              staticClass: "p-4 lg:p-6",
              class: { "pt-7 pb-3 lg:pt-9 lg:pb-4": _vm.hasErrors.length > 0 },
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.email,
                    expression: "email",
                  },
                ],
                staticClass: "mb-2",
                class: { hasErrors: _vm.hasErrors.length > 0 },
                attrs: {
                  placeholder: "Username",
                  type: "text",
                  name: "email",
                  id: "email",
                },
                domProps: { value: _vm.email },
                on: {
                  focus: function ($event) {
                    _vm.hasErrors = []
                  },
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.goToNextInput("pass")
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.email = $event.target.value
                  },
                },
              }),
              _c("div", { staticClass: "flex items-center" }, [
                _vm.type === "checkbox"
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.password,
                          expression: "password",
                        },
                      ],
                      ref: "pass",
                      staticClass: "pr-9",
                      class: { hasErrors: _vm.hasErrors.length > 0 },
                      attrs: {
                        placeholder: "Password",
                        name: "password",
                        id: "password",
                        type: "checkbox",
                      },
                      domProps: {
                        checked: Array.isArray(_vm.password)
                          ? _vm._i(_vm.password, null) > -1
                          : _vm.password,
                      },
                      on: {
                        focus: function ($event) {
                          _vm.hasErrors = []
                        },
                        input: function ($event) {
                          _vm.hasErrors = []
                        },
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.loginUser.apply(null, arguments)
                        },
                        change: function ($event) {
                          var $$a = _vm.password,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.password = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.password = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.password = $$c
                          }
                        },
                      },
                    })
                  : _vm.type === "radio"
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.password,
                          expression: "password",
                        },
                      ],
                      ref: "pass",
                      staticClass: "pr-9",
                      class: { hasErrors: _vm.hasErrors.length > 0 },
                      attrs: {
                        placeholder: "Password",
                        name: "password",
                        id: "password",
                        type: "radio",
                      },
                      domProps: { checked: _vm._q(_vm.password, null) },
                      on: {
                        focus: function ($event) {
                          _vm.hasErrors = []
                        },
                        input: function ($event) {
                          _vm.hasErrors = []
                        },
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.loginUser.apply(null, arguments)
                        },
                        change: function ($event) {
                          _vm.password = null
                        },
                      },
                    })
                  : _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.password,
                          expression: "password",
                        },
                      ],
                      ref: "pass",
                      staticClass: "pr-9",
                      class: { hasErrors: _vm.hasErrors.length > 0 },
                      attrs: {
                        placeholder: "Password",
                        name: "password",
                        id: "password",
                        type: _vm.type,
                      },
                      domProps: { value: _vm.password },
                      on: {
                        focus: function ($event) {
                          _vm.hasErrors = []
                        },
                        input: [
                          function ($event) {
                            if ($event.target.composing) return
                            _vm.password = $event.target.value
                          },
                          function ($event) {
                            _vm.hasErrors = []
                          },
                        ],
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.loginUser.apply(null, arguments)
                        },
                      },
                    }),
                _c(
                  "span",
                  {
                    staticClass: "opacity-70 -ml-7",
                    attrs: { id: "passwordIcon" },
                    on: { click: _vm.showPassword },
                  },
                  [
                    _c(
                      "svg",
                      {
                        staticClass: "h-5 w-5",
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          fill: "none",
                          viewBox: "0 0 24 24",
                          stroke: "currentColor",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                            "stroke-width": "2",
                            d: "M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ]),
              _c(
                "button",
                {
                  staticClass:
                    "hover:underline text-left text-xs py-1 font-light text-gray-400 hover:text-white mt-1",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      _vm.showModal = true
                      _vm.hasErrors = []
                    },
                  },
                },
                [_vm._v(" Hai dimenticato la password? ")]
              ),
              _c(
                "div",
                {
                  staticClass: "login-btn-container",
                  class: _vm.hasErrors.length > 0 ? "mt-4" : "mt-5",
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "login-btn",
                      class: {
                        create: _vm.createAccountUrl,
                        "bgg-secondary": !_vm.createAccountUrl,
                      },
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.loginUser.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.loading,
                              expression: "!loading",
                            },
                          ],
                          class: _vm.createAccountUrl
                            ? "text-white"
                            : "text-gray-900",
                        },
                        [_vm._v("Accesso")]
                      ),
                      _c("UILoadingIcon_B", {
                        attrs: { condition: _vm.loading },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm.hasErrors.length > 0
                ? _c(
                    "p",
                    {
                      staticClass:
                        "text-sm font-medium tracking-wide absolute top-1 lg:top-2.5",
                      staticStyle: { color: "red" },
                    },
                    [_vm._v(" *" + _vm._s(_vm.hasErrors.join(" ")) + " ")]
                  )
                : _vm._e(),
            ]
          ),
        ]),
        _c(
          "div",
          {
            staticClass:
              "w-full flex flex-col items-center text-sm justify-center rounded-b-lg",
            class: _vm.createAccountUrl ? "bgg-secondary" : "bg-gray-900 h-6",
          },
          [
            _vm.createAccountUrl
              ? _c(
                  "a",
                  {
                    staticClass:
                      "flex items-center justify-center gap-x-1.5 px-4 py-2 uppercase font-medium w-full text-center text-gray-900 hover:text-gray-900",
                    attrs: { href: _vm.createAccountUrl },
                  },
                  [_vm._v("Crea il tuo account")]
                )
              : _vm._e(),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "mb-1 py-1 px-4 w-full flex items-center rounded-t-lg uppercase bg-gradient-to-r from-primary via-blue-800 to-gray-900",
      },
      [
        _c(
          "h1",
          {
            staticClass:
              "font-bold tracking-wider sm:text-lg md:text-xl uppercase",
          },
          [_vm._v(" LOGIN - VIRTUAL ROOMS ")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }