<template>
  <div class="flex-1 flex items-center justify-center h-full">
    <div class="modal-template" style="z-index: 1" v-if="showModal">
      <!-- <transition name="modal"> -->
      <div
        class="modal-mask"
        @click="
          showModal = false;
          hasErrorsEmail = [];
        "
      ></div>
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <h2 class="title">Recupera Password</h2>
            <button
              class="modal-default-button text-2xl p-2"
              @click="
                showModal = false;
                hasErrorsEmail = [];
              "
            >
              &times;
            </button>
          </div>

          <div class="modal-body">
            <div class="lab-inp-container flex-col md:flex-row">
              <p class="mb-4">
                Ti invieremo un link per reimpostare la tua passworde
              </p>

              <input
                placeholder="Email"
                class="light-input"
                v-model="email"
                id="email"
                type="email"
                :class="{
                  hasErrors: hasErrorsEmail.length > 0,
                }"
                @focus="
                  hasErrorsEmail = [];
                  hasErrors = [];
                "
              />
              <div class="mx-auto mt-8 w-fit">
                <button
                  class="reset-password-send-btn"
                  @click.prevent="resetPassword"
                >
                  Invia
                </button>
              </div>

              <p
                v-if="hasErrorsEmail.length > 0"
                class="text-xs font-extralight"
              >
                *{{ hasErrorsEmail.join(" ") }}
              </p>
            </div>
          </div>
          <div class="modal-footer"></div>
        </div>
      </div>
      <!-- </transition> -->
    </div>
    <div
      class="modal-template fixed w-full h-full flex items-center justify-center"
      style="z-index: 1"
      v-if="showModalSkin"
    >
      <div class="modal-mask" @click="showModalSkin = false"></div>
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <h2 class="title">Seleziona il tuo casinò attuale</h2>
            <button
              class="modal-default-button text-2xl p-2"
              @click="showModalSkin = false"
            >
              &times;
            </button>
          </div>

          <div class="modal-body">
            <select
              name=""
              id=""
              v-model="skinId"
              @change="
                loginUser;
                showModalSkin = false;
              "
            >
              <option :value="null" default>Nome del casinò</option>
              <option
                v-for="skin in skins"
                :value="Number(skin.id_skin)"
                :key="skin.id_skin"
              >
                {{ skin.name }}
              </option>
            </select>
          </div>
          <div class="modal-footer"></div>
        </div>
      </div>
    </div>
    <div id="login-wrapper">
      <h2
        v-if="barName"
        class="w-full text-2xl font-semibold text-secondary text-center uppercase mb-2"
      >
        {{ barName }}
      </h2>
      <div class="relative">
        <p
          class="text-center font-bold tracking-wider mb-3 text-secondary text-lg w-full"
          v-if="skinId && skinName"
        >
          - {{ skinName }} -
        </p>
      </div>
      <div
        class="mb-1 py-1 px-4 w-full flex items-center rounded-t-lg uppercase bg-gradient-to-r from-primary via-blue-800 to-gray-900"
      >
        <h1 class="font-bold tracking-wider sm:text-lg md:text-xl uppercase">
          LOGIN - VIRTUAL ROOMS
        </h1>
      </div>
      <div class="w-full mb-1 bg-gray-700 relative">
        <form
          class="p-4 lg:p-6"
          :class="{ 'pt-7 pb-3 lg:pt-9 lg:pb-4': hasErrors.length > 0 }"
        >
          <input
            placeholder="Username"
            type="text"
            name="email"
            id="email"
            v-model="email"
            class="mb-2"
            :class="{ hasErrors: hasErrors.length > 0 }"
            @focus="hasErrors = []"
            @keyup.enter="goToNextInput('pass')"
          />
          <div class="flex items-center">
            <input
              placeholder="Password"
              class="pr-9"
              :type="type"
              name="password"
              id="password"
              v-model="password"
              :class="{ hasErrors: hasErrors.length > 0 }"
              @focus="hasErrors = []"
              @input="hasErrors = []"
              @keyup.enter="loginUser"
              ref="pass"
            />
            <span
              id="passwordIcon"
              class="opacity-70 -ml-7"
              @click="showPassword"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                /></svg
            ></span>
          </div>
          <button
            class="hover:underline text-left text-xs py-1 font-light text-gray-400 hover:text-white mt-1"
            type="button"
            @click.prevent="
              showModal = true;
              hasErrors = [];
            "
          >
            Hai dimenticato la password?
          </button>
          <div
            class="login-btn-container"
            :class="hasErrors.length > 0 ? 'mt-4' : 'mt-5'"
          >
            <button
              type="button"
              class="login-btn"
              :class="{
                create: createAccountUrl,
                'bgg-secondary': !createAccountUrl,
              }"
              @click.prevent="loginUser"
            >
              <span
                :class="createAccountUrl ? 'text-white' : 'text-gray-900'"
                v-show="!loading"
                >Accesso</span
              >
              <UILoadingIcon_B :condition="loading" />
            </button>
          </div>
          <p
            v-if="hasErrors.length > 0"
            class="text-sm font-medium tracking-wide absolute top-1 lg:top-2.5"
            style="color: red"
          >
            *{{ hasErrors.join(" ") }}
          </p>
        </form>
      </div>
      <div
        class="w-full flex flex-col items-center text-sm justify-center rounded-b-lg"
        :class="createAccountUrl ? 'bgg-secondary' : 'bg-gray-900 h-6'"
      >
        <a
          v-if="createAccountUrl"
          :href="createAccountUrl"
          class="flex items-center justify-center gap-x-1.5 px-4 py-2 uppercase font-medium w-full text-center text-gray-900 hover:text-gray-900"
          >Crea il tuo account</a
        >
      </div>
      <!-- <button
        class="p-4 absolute right-0 bottom-0"
        @click="showModalSkin = true"
      >
        Show skin dropdown
      </button> -->
    </div>
  </div>
</template>

<script>
import {
  login,
  resetPassword,
  loginFromSkinWithCredentials,
} from "@/api/authRepository";
import userRepository from "@/api/userRepository";
import settingsRepository from "@/api/settingsRepository";
import { getCredit } from "@/api/creditRepository";
import UILoadingIcon_B from "../components/utils/UILoadingIcon_B.vue";
import analyticsConfig from "../mixins/analyticsConfig.vue";
import tokensRepository from "@/api/tokensRepository";
import Modal from "@/components/utils/Modal";
import { getSkins } from "@/api/geralRepository";

export default {
  name: "Login",

  components: { UILoadingIcon_B, Modal },

  mixins: [analyticsConfig],

  data() {
    return {
      type: "password",
      email: "",
      password: "",
      hasErrors: [],
      hasErrorsEmail: [],
      showModal: false,
      nextUrl: this.$route.query.nextUrl,
      createAccountUrl: this.$route.query.registrationUrl
        ? this.$route.query.registrationUrl
        : null,
      barName: this.$route.query.roomName ? this.$route.query.roomName : null,
      loading: false,
      showModalSkin: false,
      skinId: null,
      skins: null,
    };
  },

  computed: {
    skinName() {
      const skin = this.skins.find((s) => s.id_skin == this.skinId);
      console.log(this.skins);
      console.log(skin);
      return skin.name;
    },
  },

  created() {
    getSkins()
      .then((res) => {
        this.skins = res.data;
      })
      .catch(() => console.error("couldnt get skins"));

    const params = new URLSearchParams(window.location.search);
    if (params.get("registrationUrl")) {
      this.createAccountUrl = params.get("registrationUrl");
    }
    if (params.get("roomName")) {
      this.barName = params.get("roomName");
    }
    if (this.nextUrl == "/") {
      this.nextUrl = null;
      let query = Object.assign({}, this.$route.query);
      delete query.nextUrl;
      this.$router.replace({ query });
    }

    // if (typeof this.createAccountUrl !== 'string') {
    //    this.createAccountUrl = null;
    // }

    // if (this.$route.query.roomName) {
    //    this.barName = this.$route.query.roomName;
    // }
  },

  methods: {
    goToNextInput(input) {
      if (!!this.email === false) {
        this.hasErrors.push("Per favore, inserisci il tuo username.");
        return;
      }
      this.$refs[input].focus();
    },

    loginUser() {
      //TODO EXALOGIC temporariamente disabled

      // if (this.loading) return;
      this.hasErrors = [];

      if (!!this.email === false) {
        this.hasErrors.push("Per favore, inserisci il tuo username.");
        return;
      } else if (!!this.password === false) {
        this.hasErrors.push("Per favore, inserisci i dati di accesso.");
        return;
      }

      this.loading = true;

      if (process.env.VUE_APP_IS_SKIN_INTEGRATED === "true") {
        const obj = {
          loginField: this.email,
          password: this.password,
          skin: this.skinId,
        };

        loginFromSkinWithCredentials(obj)
          .then((res) => {
            if (res.status == 202) {
              console.log("vou p o auth polling");
              this.authPolling(this.email, this.password);
              return;
            }

            const auth = {
              accessToken: res.data.accessToken,
              refreshToken: res.data.refreshToken,
              deviceId: res.data.deviceId,
              userId: res.data.userId,
              externalUserId: res.data.externalUserId,
              numContract: res.data.numContract,
              sessionId: res.data.sessionId,
              isAuthenticated: true,
            };

            this.$store.dispatch("auth/setUpAuth", auth);

            let promises = [
              userRepository.getUser(res.data.userId),
              settingsRepository.getSettings(res.data.userId),
              getCredit(res.data.userId),
              tokensRepository.getCredit(res.data.userId),
              userRepository.getAvatar(res.data.userId),
            ];

            Promise.all(promises).then(
              ([user, settings, credit, tokens, avatar]) => {
                user.data, settings.data, credit.data;

                // Update userInfo in store
                this.$store.commit("userInfo/mutateUser", user.data);
                this.$store.commit("userInfo/mutateSettings", settings.data);
                this.$store.commit("userInfo/mutateCredit", credit.data);
                this.$store.commit(
                  "userInfo/mutateAvatarCustom",
                  avatar.data && avatar.data.photo ? avatar.data.photo : null
                );
                this.$store.commit("userInfo/mutateTokens", tokens.data);

                try {
                  if (!!process.env.VUE_APP_EVENTS_SOCKET_SERVER_URL) {
                    this.$store.dispatch("socket/init");
                  }
                } catch (err) {
                  console.error(err);
                }

                if (this.nextUrl) {
                  this.$router.push({ path: `${this.nextUrl}` });
                } else {
                  if (this.$store.getters["userInfo/getGoToBar"] == true) {
                    let defaultBar =
                      this.$store.getters["userInfo/getDefaultBar"];
                    this.$router.push({
                      name: "Bar",
                      params: { barName: defaultBar },
                    });
                  } else {
                    this.$router.push({ name: "SlotsBar" });
                  }
                }
              }
            );
          })
          .catch((err) => {
            this.loading = false;

            if (err.response && err.response.status == 418) {
              // this.skinId = Number(this.skins[0].id_skin);
              this.showModalSkin = true;
            }

            if (
              err.response &&
              err.response.status == 302 &&
              !!err.response.data.redirectUrl
            ) {
              window.location.href = err.response.data.redirectUrl;
            } else {
              this.hasErrors.push("Qualcosa è andato storto.");
            }
          });
      } else {
        login({ loginField: this.email, password: this.password })
          .then((response) => {
            this.$store.dispatch(
              "auth/updateAccessToken",
              response.data.accessToken
            );
            this.$store.dispatch("auth/updateDeviceId", response.data.deviceId);
            this.$store.dispatch(
              "auth/updateRefreshToken",
              response.data.refreshToken
            );
            this.$store.dispatch("auth/updateUserId", response.data.userId);
            this.$store.dispatch(
              "auth/updateExternalUserId",
              response.data.externalUserId
            );
            this.$store.dispatch("auth/updateIsAuthenticated", true);
            this.$store.commit(
              "auth/mutateNumContract",
              response.data.numContract
            );

            let promises = [
              // TODO passar accesstoken
              userRepository.getUser(response.data.userId),
              settingsRepository.getSettings(response.data.userId),
              getCredit(response.data.userId),
              tokensRepository.getCredit(response.data.userId),
              userRepository.getAvatar(response.data.userId),
            ];
            Promise.all(promises).then(
              ([user, settings, credit, tokens, avatar]) => {
                user.data, settings.data, credit.data;

                // Update userInfo in store
                this.$store.commit("userInfo/mutateUser", user.data);
                this.$store.commit("userInfo/mutateSettings", settings.data);
                this.$store.commit("userInfo/mutateCredit", credit.data);
                this.$store.commit(
                  "userInfo/mutateAvatarCustom",
                  avatar.data && avatar.data.photo ? avatar.data.photo : null
                );
                this.$store.commit("userInfo/mutateTokens", tokens.data);
                try {
                  if (!!process.env.VUE_APP_EVENTS_SOCKET_SERVER_URL) {
                    this.$store.dispatch("socket/init");
                  }
                } catch (err) {
                  console.error(err);
                }

                this.userIdAnalyticsConfig(user.data.nickname);

                if (this.nextUrl) {
                  this.$router.push({ path: `${this.nextUrl}` });
                } else {
                  if (this.$store.getters["userInfo/getGoToBar"] == true) {
                    let defaultBar =
                      this.$store.getters["userInfo/getDefaultBar"];
                    this.$router.push({
                      name: "Bar",
                      params: { barName: defaultBar },
                    });
                  } else {
                    this.$router.push({ name: "SlotsBar" });
                  }
                }
              }
            );
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
            if (
              err.response &&
              (err.response.status == 401 || err.response.status == 400)
            ) {
              this.hasErrors.push("Credenziali errate. Riprova.");
            } else {
              this.hasErrors.push("Qualcosa è andato storto.");
            }
            console.error("LOGIN ERROR:", err);
          });
      }
    },
    showPassword() {
      let icon = document.getElementById("passwordIcon");
      if (this.type === "password") {
        this.type = "text";
        icon.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
</svg>`;
      } else {
        this.type = "password";
        icon.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
</svg>`;
      }
    },

    resetPassword() {
      this.hasErrorsEmail = [];
      resetPassword({ email: this.email })
        .then((response) => {
          this.showModal = false;
        })
        .catch((err) => {
          console.log("RESET PASSWORD:", err);
          if (
            err.response &&
            (err.response.status == 400 || err.response.status == 404)
          ) {
            this.hasErrorsEmail.push("Email non valido.");
          } else {
            this.hasErrorsEmail.push("Qualcosa è andato storto.");
          }
        });
    },

    authPolling(email, password) {
      console.log("tou no auth polling");
      const obj = {
        loginField: email,
        password: password,
        polling: true,
      };
      loginFromSkinWithCredentials(obj)
        .then((res) => {
          const auth = {
            accessToken: res.data.accessToken,
            refreshToken: res.data.refreshToken,
            deviceId: res.data.deviceId,
            userId: res.data.userId,
            externalUserId: res.data.externalUserId,
            numContract: res.data.numContract,
            sessionId: res.data.sessionId,
            isAuthenticated: true,
          };

          this.$store.dispatch("auth/setUpAuth", auth);

          let promises = [
            userRepository.getUser(res.data.userId),
            settingsRepository.getSettings(res.data.userId),
            getCredit(res.data.userId),
            tokensRepository.getCredit(res.data.userId),
            userRepository.getAvatar(res.data.userId),
          ];

          Promise.all(promises).then(
            ([user, settings, credit, tokens, avatar]) => {
              user.data, settings.data, credit.data;

              // Update userInfo in store
              this.$store.commit("userInfo/mutateUser", user.data);
              this.$store.commit("userInfo/mutateSettings", settings.data);
              this.$store.commit("userInfo/mutateCredit", credit.data);
              this.$store.commit(
                "userInfo/mutateAvatarCustom",
                avatar.data && avatar.data.photo ? avatar.data.photo : null
              );
              this.$store.commit("userInfo/mutateTokens", tokens.data);

              try {
                if (!!process.env.VUE_APP_EVENTS_SOCKET_SERVER_URL) {
                  this.$store.dispatch("socket/init");
                }
              } catch (err) {
                console.error(err);
              }

              if (this.nextUrl) {
                this.$router.push({ path: `${this.nextUrl}` });
              } else {
                if (this.$store.getters["userInfo/getGoToBar"] == true) {
                  let defaultBar =
                    this.$store.getters["userInfo/getDefaultBar"];
                  this.$router.push({
                    name: "Bar",
                    params: { barName: defaultBar },
                  });
                } else {
                  this.$router.push({ name: "SlotsBar" });
                }
              }
            }
          );
        })
        .catch((err) => {
          if (err.response.status == 403) {
            this.$router.push({ name: "404" });
          }

          if (err.response.status == 503) {
            let timeout = 1000;
            setTimeout(() => {
              this.authPolling(email, password);
            }, timeout);
          }
        });
    },
  },
};
</script>

<style lang="postcss" scoped>
select {
  -webkit-appearance: none;
  height: 30px;
  background: url(/img/icons/arrow-down.png);
  background-repeat: no-repeat;
  background-size: 12px 7px;
  background-position: right;
  background-origin: content-box, padding-box;
  text-align-last: center;
  @apply bg-gray-700 rounded-full text-center py-1 px-3 w-full my-1;
}

.login-btn {
  box-shadow: inset -1px -2px 5px rgba(189, 108, 3, 0.7);
  @apply w-40 h-8 rounded-full font-bold uppercase text-gray-900;

  &.create {
    @apply bg-gray-800 border border-secondary;
  }
}

.reset-password-send-btn {
  /* background: #232323; */
  background: theme(colors.gray.900);
  @apply border rounded-full border-secondary font-bold py-1 uppercase w-40;
}

#login-wrapper {
  @apply w-5/6;
  @screen sm {
    max-width: 350px;
  }
  @screen lg {
    min-width: 400px;
    @apply w-1/4 shadow-lg;
  }
}

.hasErrors {
  border: 2px solid red !important;
}

.modal-template {
  @apply fixed w-full h-full flex items-center justify-center;
}

.modal-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(23, 23, 23, 0.9);
  z-index: -1;

  @screen lg {
    @apply mt-0;
  }
}

.modal-wrapper {
  width: 98%;

  @screen xs {
    width: 75%;
  }

  @screen sm {
    width: 60%;
  }

  @screen md {
    width: 50%;
  }

  @screen lg {
    width: 40%;
  }

  @screen xl {
    width: 30%;
  }

  @screen 2xl {
    width: 25%;
  }
}

.modal-container {
  min-width: fit-content;
  max-width: 100%;
  height: fit-content;
  max-height: 100%;
  margin: 0px auto;
  background-color: theme(colors.gray.800);
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease;
  @apply rounded-xl;
}

.modal-header {
  @apply relative pt-6 pl-6 pr-16 pb-1;

  h3 {
    @apply text-secondary text-lg uppercase font-semibold;
  }

  @screen md {
    @apply text-xl;
  }
}

.modal-body {
  @apply flex justify-center px-6 pb-3;

  p {
    @apply text-sm;
  }

  @screen md {
    @apply text-base;
  }

  @screen lg {
    height: 87%;
    @apply items-center;
  }
}

.modal-footer {
  @apply pb-4;
}

.modal-default-button {
  float: right;
  @apply absolute top-2 right-2 py-0;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

/* .padding-left-30 {
   padding-left: 30px;
} */

.login-btn-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  @apply w-full;
}

.disabled {
  pointer-events: none;
  opacity: 40%;
  cursor: not-allowed;
  /* @apply opacity-40 cursor-not-allowed; */
}

@media (hover: hover) {
  .login-btn {
    &.create {
      background: linear-gradient(
        168deg,
        #ff9d00 18.42%,
        #ffa800 47.15%,
        #ffd600 85.28%
      );
    }
  }

  .reset-password-send-btn {
    background: linear-gradient(140deg, #f28928 23.81%, #ffbc10 73.15%);
    @apply text-gray-900;
  }
}
</style>
